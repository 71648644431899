<template>
    <v-container fluid v-if="hasAccessToPage" class="hoh-entry-form-entries-page">
        <!-- title -->
        <v-row justify="center" class="mb-4 mt-4" v-if="selectedDate">
            <v-col cols="10" sm="12" md="12" lg="10" xl="8" style="position:relative;">

                <h2 class="font-weight-bold basil--text pb-2"> 
                    <v-btn fab @click="$router.push({name: 'HohEntryFormPage'})" class="me-2" small elevation="2" color="primary">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-icon large color="grey darken-3">mdi-calendar</v-icon> 
                    <template v-if="selectedDate">
                        {{ formatDate(selectedDate) }}
                    </template>
                </h2>
                <v-divider class="mt-2"></v-divider>
            </v-col>
        </v-row>
        <!-- table -->
        <v-row justify="center">
            <v-col cols="12" sm="12" md="10" lg="8">
                <v-card>
                    <v-data-table 
                        v-if="appointments.length > 0"
                        :headers="headers" :loading="loadingAppointments" loading-text="Loading... Please wait" :items="appointments" disable-pagination :hide-default-footer="true">        
                        <template v-slot:body="{items}">
                            <tbody>
                                <tr class="entry-row" v-for="(item, index) in items" :key="index">
                                    <td> <v-icon class="py-3" large> mdi-hospital-building</v-icon></td>
                                    <td>{{ formatDateTime(item.dateTimeAppointment)}}</td>
                                    <td>{{ item.policyNumber }}</td>
                                    <td>{{ item.selfPay ? 'Yes' : 'No' }}</td>
                                    <td>{{ item.firstNamePatient }}</td>
                                    <td>{{ item.lastNamePatient }}</td>
                                    <td>{{ formatDate(item.birthDatePatient) }}</td>
                                    <td >{{ item.doctorEntry ? item.doctorEntry.name : 'No doctor' }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                    <template v-if="loadingAppointments">
                        <v-skeleton-loader 
                        v-for="(page,i) in 10" :key="'skeletonloader-' + i"
                            type="list-item"
                            ref="skeleton"
                            class="mx-auto my-0"
                        ></v-skeleton-loader>
                    </template>
                </v-card>

                <div class="pa-8 text-center title" v-if="!loadingAppointments && appointments.length == 0">
                    No entries on this date. Use plus button to add.
                </div>
            </v-col>
        </v-row>
        <!-- Modal -->
        <HohEntryFormModal :selectedDate="selectedDate" />
        
    </v-container>
    <NoAccessMessage v-else />
</template>

<script>
import SharedFunctions from '@/mixins/SharedFunctions.vue'
import moment from 'moment'
import HohEntryFormModal from '@/views/HohEntryFormPage/HohEntryFormEntriesPage/components/HohEntryFormModal.vue'

export default {
    mixins: [SharedFunctions],
    components: {
        HohEntryFormModal
    },
    data() {
        return {
            hasAccessToPage: false,
            appointments: [],
            loadingAppointments: false,
            headers: [
              { text: 'Type', align: 'start', sortable: false, value: 'thumb'},
              { text: 'Date and Time', value: 'date_time', sortable: false},
              { text: 'Policy Number', value: 'policy_number', sortable: false},
              { text: 'Self Pay', value: 'self_Pay', sortable: false},
              { text: 'First Name', value: 'first_name', sortable: false},
              { text: 'Last Name', value: 'last_name', sortable: false},
              { text: 'Date of Birth', value: 'dob', sortable: false},
              { text: 'Doctor Name', value: 'doctor', sortable: false },
            ]
        }
    },
    computed: {
        selectedDate() {
            return this.$route.params.selectedDate;
        }
    },
    created() {
        this.hasAccessToPage = this.hasAccessToPageMixin();
        this.getEntries();
    },
    methods: {
        formatDate(d) {
            return moment(d, 'YYYY-MM-DD').format('DD-MMM-YYYY');
        },
        formatDateTime(d) {
            return moment.utc(d, 'YYYY-MM-DDTHH:mm').local().format('DD-MMM-YYYY | h:mm A');
        },
        getEntries() {
            if(this.selectedDate) {
                this.$store.dispatch('getHohEntryFormsAppointments', {minDate: this.selectedDate, maxDate: this.selectedDate}).then((data) => {
                    this.appointments = data;
                });
            } 
        }
    }

}
</script>

<style lang="scss">
    .hoh-entry-form-entries-page{
        .entry-row:hover{
            //cursor: pointer;
        }
    }
</style>