<template>
  <v-dialog v-model="showDialog" width="800" scrollable persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark fab bottom right color="primary" fixed large v-bind="attrs" v-on="on">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-card class="hoh-entry-form">
      <v-card-title class="headline primary white--text" primary-title>Create HOH entry on {{ formatDate(selectedDate) }}</v-card-title>
      <v-card-text>
        <v-form style="max-width: 450px; margin: 0 auto" ref="hohEntryForm">
          <div class="my-4">
            <label class="primary--text title">Application Info</label>
            <v-divider class="title-border"></v-divider>
          </div>

          <!-- application date -->
          <v-menu v-model="applicationDateMenuOpen" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedApplicationDate"
                label="Application Date"
                readonly
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker @change="saveApplicationDate($event)" @input="applicationDateMenuOpen = false"></v-date-picker>
          </v-menu>

          <!-- applicationThrough -->
          <v-select :items="['Phone', 'Email', 'WhatsApp']" label="Application Through" v-model="formData.applicationThrough"></v-select>

          <div class="my-4">
            <label class="primary--text title">Personal Info</label>
            <v-divider class="title-border"></v-divider>
          </div>

          <!-- first name patient -->
          <v-text-field
            label="First Name Patient"
            type="text"
            :rules="[(v) => !!v || 'This field is required']"
            class="required"
            v-model="formData.firstNamePatient"
          ></v-text-field>

          <!-- last name patient -->
          <v-text-field
            label="Last Name Patient"
            type="text"
            class="required"
            :rules="[(v) => !!v || 'This field is required']"
            v-model="formData.lastNamePatient"
          ></v-text-field>

          <!-- Birthday -->
          <v-dialog
            ref="dialogBirthDay"
            v-model="birthDatePatientDialogOpen"
            :close-on-content-click="false"
            transition="scale-transition"
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="birthdaypicker-custom"
                :class="required ? 'required' : ''"
                :value="computedBirthDatePatient"
                required
                append-icon="mdi-calendar"
                clearable
                label="Birthdate Patient"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker ref="picker" :max="new Date().toISOString().substr(0, 10)" min="1900-01-01" @change="saveBirthDay($event)">
            </v-date-picker>
          </v-dialog>

          <!-- address patient -->
          <v-text-field label="Address Patient" type="text" v-model="formData.addressPatient"></v-text-field>

          <!-- phone patient -->
          <v-text-field label="Phone Patient" type="tel" v-model="formData.phonePatient"></v-text-field>

          <!-- email -->
          <v-text-field label="Email Patient" type="email" v-model="formData.emailPatient"></v-text-field>

          <div class="my-4">
            <label class="primary--text title">Medical Info</label>
            <v-divider class="title-border"></v-divider>
          </div>

          <!-- self pay -->
          <v-switch :disabled="enableSelfPay" v-model="formData.selfPay" label="Self Pay"> </v-switch>

          <!-- azv number -->
          <v-text-field :disabled="formData.selfPay" label="Policy/AZV Number" type="text" v-model="formData.policyNumber"></v-text-field>

          <!-- test number tube -->
          <v-text-field label="Test Tube Number" type="text" v-model="formData.testTubeNumber"></v-text-field>

          <!-- doctor info -->
          <v-autocomplete
            clearable
            :disabled="doctorOptions.length == 0"
            :loading="doctorOptions.length == 0"
            v-model="formData.doctorEntry.id"
            data-lpignore="true"
            :items="doctorOptions"
            label="Doctor"
          >
          </v-autocomplete>

          <div class="my-4">
            <label class="primary--text title">Appointment Info</label>
            <v-divider class="title-border"></v-divider>
          </div>

          <!-- appointment date -->
          <v-text-field
            v-model="computedAppointmentDate"
            label="Appointment Date"
            readonly
            disabled
            append-icon="mdi-calendar"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>

          <!-- appointment time -->
          <v-dialog
            ref="dialogAppointmentTime"
            v-model="appointmentDateMenuOpen"
            :close-on-content-click="false"
            :return-value.sync="time"
            persistent
            transition="scale-transition"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedAppointmentTime"
                label="Appointment Time"
                :rules="[(v) => !!v || 'This field is required']"
                readonly
                class="required"
                append-icon="mdi-clock"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker v-if="appointmentDateMenuOpen" v-model="appointmentTime" @input="appointmentTimeMenuOpen = false">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="cancelAppointmentDateTime()">Cancel</v-btn>
              <v-btn text color="primary" @click="saveAppointmentDateTime()">OK</v-btn>
            </v-time-picker>
          </v-dialog>

          <div class="my-4">
            <label class="primary--text title">Comment</label>
            <v-divider class="title-border"></v-divider>
          </div>

          <!-- comment -->
          <v-textarea label="Comment" outlined v-model="formData.comment"></v-textarea>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeModal()"> Cancel </v-btn>
        <v-btn color="success" :disabled="submittingForm" @click="submit()" class="mx-4">
          Submit
          <v-icon right>mdi-send</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import { HohEntryFormDataKeys } from '@/views/HohEntryFormPage/data/hoh-entry-form-data.js';

export default {
  props: ['selectedDate'],
  data() {
    return {
      showDialog: false,
      formDataPrestine: JSON.stringify(new HohEntryFormDataKeys()),
      formData: new HohEntryFormDataKeys(),
      applicationDateMenuOpen: false,
      appointmentDateMenuOpen: false,
      appointmentDate: null,
      appointmentTime: null,
      sampleDateMenuOpen: false,
      birthDatePatientDialogOpen: false,
      required: false,
      submittingForm: false,
    };
  },
  watch: {
    birthDatePatientDialogOpen(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
    showDialog(n) {
      if (n) this.formData.applicationDate = moment().format('YYYY-MM-DD') + 'T01:00:00.000Z[UTC]';
    },
  },
  computed: {
    computedApplicationDate() {
      return this.formData.applicationDate ? moment(this.formData.applicationDate, 'YYYY-MM-DD').format('D-MMMM-YYYY') : '';
    },
    computedAppointmentDate() {
      return this.selectedDate ? moment(this.selectedDate, 'YYYY-MM-DD').format('D-MMMM-YYYY') : '';
    },
    computedAppointmentTime() {
      return this.appointmentTime ? moment(this.appointmentTime, 'HH:mm').format('hh:mm A') : '';
    },
    computedBirthDatePatient() {
      return this.formData.birthDatePatient ? moment(this.formData.birthDatePatient, 'YYYY-MM-DD').format('D-MMMM-YYYY') : '';
    },
    enableSelfPay() {
      if (this.formData.policyNumber) return true;
      else return false;
    },
    doctorOptions() {
      return this.$store.state.doctorOptions;
    },
  },
  created() {
    this.formData.insertDate = moment().format('YYYY-MM-DD') + 'T01:00:00.000Z[UTC]';
    this.appointmentDate = moment(this.selectedDate).format('YYYY-MM-DD');

    if (this.doctorOptions.length == 0) this.$store.dispatch('getDoctorOptions');
  },
  methods: {
    submit() {
      if (!this.$refs.hohEntryForm.validate()) return;

      this.formData.insertDate = this.formData.applicationDate;
      this.formData.createdBy = this.$store.state.userObject.preferred_username;

      let dataToPost = JSON.parse(JSON.stringify(this.formData));

      if (!dataToPost.doctorEntry.id) delete dataToPost.doctorEntry;

      this.submittingForm = true;
      this.$store.commit('SET_SHOW_LOADER', { action: true, text: 'One moment...' });
      this.$store.dispatch('submitHohEntryForm', dataToPost).then((data) => {
        this.submittingForm = false;
        this.$store.commit('SET_SHOW_LOADER', { action: false, text: null });
        this.resetForm();
        this.$parent.getEntries();
        this.showDialog = false;

        if (data) {
          this.resetForm();
          let payload = {
            open: true,
            text: 'Entry successfully created.',
            color: 'success',
          };
          this.$store.commit('SET_SNACKBAR', payload);
        }
      });
    },
    formatDate(d) {
      return moment(d, 'YYYY-MM-DD').format('DD-MMM-YYYY');
    },
    saveBirthDay(value) {
      this.formData.birthDatePatient = value + 'T13:00:00.000Z[UTC]';
      this.birthDatePatientDialogOpen = false;
    },
    saveApplicationDate(value) {
      this.formData.applicationDate = value + 'T01:00:00.000Z[UTC]';
    },
    saveAppointmentDateTime() {
      this.appointmentDateMenuOpen = false;
      const appointmentDateTime = this.selectedDate + 'T' + this.appointmentTime;
      this.formData.dateTimeAppointment =
        moment.utc(appointmentDateTime, 'YYYY-MM-DDTHH:mm').utcOffset(240).format('YYYY-MM-DDTHH:mm') + ':00.000Z[UTC]';
    },
    cancelAppointmentDateTime() {
      this.appointmentDateMenuOpen = false;
      this.appointmentTime = null;
    },
    closeModal() {
      this.showDialog = false;
      this.resetForm();
    },
    resetForm() {
      this.formData = JSON.parse(this.formDataPrestine);
      this.appointmentTime = null;
    },
  },
};
</script>

<style lang="scss">
.hoh-entry-form {
}
</style>