
export class HohEntryFormDataKeys {
    constructor() {
        return {
            addressPatient: null,
            applicationDate: null,
            applicationThrough: null,
            birthDatePatient: null,
            createdBy: null,
            dateTimeAppointment: null,
            doctorEntry: {
                id: null
            },
            emailPatient: null,
            firstNamePatient: null,
            insertDate: null,
            lastNamePatient: null,
            phonePatient: null,
            policyNumber: null,
            selfPay: false,
            testTubeNumber: null,
            comment: null
        }
    }
  }